import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';

import { fetchWaitListApi } from '@/apis/member.api';
import { STATUS_CODE } from '@/constants/error.const';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { Pagination } from '@/types/common/pagination.type';
import type { ProjectsByTeamId } from '@/types/member/member-model.type';
import type { WaitListResponse } from '@/types/member/member-response.type';

export const useMemberStore = defineStore('memberStore', () => {
  const userStore = useUserStore();
  const { selectedGroupId } = storeToRefs(userStore);
  const projectList = ref<ProjectsByTeamId[]>([]);

  const waitListData = ref<Pagination<WaitListResponse[] | undefined>>();

  const fetchWaitList = async (page: number) => {
    try {
      const data = await fetchWaitListApi(selectedGroupId.value, { page });
      waitListData.value = data;
      return true;
    } catch (error) {
      const err: ErrorResponse = error as ErrorResponse;
      if (err.statusCode === STATUS_CODE.READ_WRITE_ACCESS_DENIED) {
        return false;
      }
    }
  };

  const setProjectList = (projects: ProjectsByTeamId[]) => {
    projectList.value = projects;
  };

  return {
    projectList,
    setProjectList,
    fetchWaitList,
    waitListData
  };
});
